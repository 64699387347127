.multi-input {
    display: block;
    align-items: center;
    border-radius: .25rem;
  }
  
  .multi-input .form-control {
    display: inline-flex;
    width: auto;
    padding: 0;
    border: 0;
  
    &:focus {
      border: 0;
      box-shadow: none;
    }
  }
  
  .multi-element {
    display: inline-block;
    min-width: 0px;
    height: 28px;
    margin: 0 5px 5px 0;
    background-color: #e6e6e6;
    border-radius: 2px;
    box-sizing: border-box;
  }
  
  .multi-element-text {
    padding: 3px 3px 3px 6px;
    border-radius: 2px;
    color: rgb(51, 51, 51);
    font-size: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
  }
  
  .multi-element-remove {
    margin: 6px 6px 0 7px;
    font-size: 10px;
    cursor: pointer;
    transition: color .2s;
  
    &:hover {
      color: #bb4f4f;
    }
  }