html,
body,
#root,
.App,
.App > div {
    height: 100%;
}

.main {
    min-height: 100%;
    display: flex;
}

.content {
    position: relative;
    padding: 30px 20px;
    flex: 1;
}

.item-message-header {
    margin: 0 0 30px;
}

.title-message-header {
    margin: 0 0 15px;
}

.text-input {
    min-width: 150px;
    margin: 0 15px 0 0;
    flex-shrink: 0;
}

.hidden-inputs {
    display: none;

    &.is-active {
        margin: 15px 0 0;
        display: block;
    }
}

.edit-resource,
.delete-resource {
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.2s;

    &:hover {
        opacity: 0.7;
    }
}

.edit-resource {
    margin: 0 5px 0 0;
    color: var(--blue);
}

.delete-resource {
    color: var(--red);
}

.form-group {
    width: 100%;
}

.alert-main {
    width: calc(100% - 40px);
    top: 0;
    margin: 30px auto;
    z-index: 1031;
}