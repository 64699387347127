.page-loading {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 200px 0 0;
  background: rgba(255,255,255, 0.7);
  z-index: 10;
}
