.auth-modal .modal-dialog {
  max-width: 350px;
}

.wide-btn {
  width: 100%;
  margin: 10px 0 0;
}

.auth-footer {
  text-align: center;
}

.bottom-links-container {
  display: flex;
  justify-content: space-evenly;
  margin: 10px 0 0;
}

.bottom-link-container {
  margin: 10px 0 0;
}

.modal-content .global-alert {
  position: fixed;
  width: 98%;
  left: 1%;
  top: 1%;
}
