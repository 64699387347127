.alert-container {
  position: absolute;
  width: calc(100% - 40px);
  top: 0;
  margin: 85px 20px 0 0;
  z-index: 1031;
}

.alert-container-modal {
  z-index: 99999;
}