.btn.btn-link {
  padding: 0;
}

.table td {
  vertical-align: middle;
}

.dnd-icon {
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  left: 15px;
  margin: 15px 0 0 0;
  background-image: url(assets/dnd-icon.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.sortable-table {
  margin: 0 0 0 25px;
}