.sidebar {
    width: 300px;
    padding: 20px;
    border-right: 1px solid #d4d4d4;

    &__pages {
        padding: 0;
        list-style: none;

        a {
            &:hover {
                text-decoration: none;
            }
        }

        &__item {
            padding: 10px;
            font-size: 18px;
            color: #000;
            opacity: 1;
            cursor: pointer;
            background: transparent;
            transition: background 0.2s, opacity 0.2s;

            &:hover {
                opacity: 0.7;
            }

            &--active {
                border-radius: 4px;
                color: #fff;
                background: #626b71;

                &:hover {
                    opacity: 1;
                    cursor: default;
                }
            }
        }
    }
}
