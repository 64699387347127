.page {
  &__title {
    margin-bottom: 2rem;
  }

  &__tab-content {
    padding: 30px 0;
  }

  &__btn-container {
    display: flex;
    justify-content: flex-end;
    margin: 0 30px 20px;

    &.row {
      justify-content: space-between;
      margin: 0 0 20px;
    }
  }
}

.btn.btn-link:focus {
  box-shadow: none;
}

.nav-tabs .nav-link.error-tab {
  color: #dc3545;
}

.nav-tabs .nav-link.active.error-tab {
  border-color: #dc3545 #dc3545 #fff;
}

.was-validated .form-check .form-check-input:valid~.form-check-label {
  color: inherit;
}

.was-validated .multi-input-container .form-control:valid {
  border-color: #ced4da;
  background-image: none;
}

.was-validated .multi-input-container .form-control:valid:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255, 0.25);
}
