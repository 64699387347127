.input-container {
    .input-group .form-control.input-element,
    .input-group:not(.has-validation)>.form-control:not(:last-child) {
        border-top-right-radius: .25rem;
        border-bottom-right-radius: .25rem;
    }
}

.datelabel {
    position: relative;
    width: 100%;

    &.datetime {
        display: inline-flex;
        width: 50%;
    }
}

.input-container {
   .hidden {
        position: absolute;
        width: 75%;
        border-right: 0;
        border-radius: .25rem 0 0 .25rem;
        background-color: #fff;
    }

    .hidden:focus {
        border-color: #ced4da;
        box-shadow: none;
    }
}

.radio-label.form-label {
    padding-top: 0;
    padding-bottom: 0;
}

.was-validated .input-element.form-control:valid {
    border-color: #ced4da;
    background-image: none;
}

.was-validated .input-element.form-control:valid:focus {
    border-color: #80bdff;
    background-image: none;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255, 0.25);
}

.was-validated .form-check-inline .form-check-input:valid~.form-check-label {
    color: inherit;
}