.ddex-navbar.navbar {
    background-color: white;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
}

.ddex-footer {
    padding: 1rem 0;
    text-align: center;
    background-color: white;

    .footer-text {
        margin: 0;
        font-size: 12px;
    }
}
