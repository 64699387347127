.breadcrumbs-container {
  position: relative;
  margin: 0 0 60px 0;
}

.breadcrumbs-link {
  text-transform: capitalize;
}

.export-btn-container {
  position: absolute;
  top: 9px;
  right: 20px;
}